<template>
  <div class="Welcome">
    <Header />

    <div class="ContentWrap">
      <span class="Title">{{ mixWB('WELCOME') }}</span>
      <p>{{ mixWB('WELCOME_LINE_1') }}</p>
      <p>{{ mixWB('WELCOME_LINE_2') }}</p>
      <p>{{ mixWB('WELCOME_LINE_3') }}</p>
      <p>{{ mixWB('WELCOME_LINE_4') }}</p>
      <p>{{ mixWB('WELCOME_LINE_5') }}</p>

      <br/>
      <br/>

      <span class="Title">{{ mixWB('HERE_IS_HOW_TO_DO_IT') }}</span>

      <div class="Box">
        <div class="Number">1</div>
        <div class="Text">
          <span>{{ mixWB('WELCOME_STEP_1_LINE_1') }}</span>
          <span>{{ mixWB('WELCOME_STEP_1_LINE_2') }}</span>
          <span>{{ mixWB('WELCOME_STEP_1_LINE_3') }}</span>
        </div>
        <div class="Empty"/>
      </div>

      <div class="SplitLine"/>

      <div class="Box">
        <div class="Empty"/>
        <div class="Text">
          <span>{{ mixWB('WELCOME_STEP_2_LINE_1') }}</span>
          <ul>
            <li>{{ mixWB('WELCOME_STEP_2_DOT_1') }}</li>
            <li>{{ mixWB('WELCOME_STEP_2_DOT_2') }}</li>
            <li>{{ mixWB('WELCOME_STEP_2_DOT_3') }}</li>
          </ul>
        </div>
        <div class="Number">2</div>
      </div>

      <div class="SplitLine"/>

      <div class="Box">
        <div class="Number">3</div>
        <div class="Text">
          <span>{{ mixWB('WELCOME_STEP_3_LINE_1') }}</span>
          <span>{{ mixWB('WELCOME_STEP_3_LINE_2') }}</span>
          <span>{{ mixWB('WELCOME_STEP_3_LINE_3') }}</span>
        </div>
        <div class="Empty"/>
      </div>

      <div class="ButtonWrap">
        <Button
          :text="currentUserNextStep.started ? mixWB('CONTINUE') : mixWB('GET_STARTED')"
          size="large"
          :fullWidth="false"
          @button-click="onNextClick" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Questionnaire/Header'
import Button from '@/components/Buttons/Button'
import { mapGetters } from 'vuex'

export default {
  name: 'Welcome',
  computed: {
    ...mapGetters([
      'currentUserAnswers',
      'currentUserNextStep',
    ]),
  },
  methods: {
    onNextClick() {
      this.$router.push({
        name: 'Questionnaire',
        params: { questionnaireName: this.currentUserNextStep.schema.name },
      })
    },
  },
  components: {
    Header,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .Welcome
    display block

  .ContentWrap
    display flex
    flex-direction column
    align-items center
    width 800px
    margin 0 auto
    padding-bottom 200px
    p,
    span
      text-align center
      max-width 600px
    .Title
      font-size 1.5rem
      font-weight bold
      text-align center
      margin-bottom 15px

  .Box
    display flex
    align-items center
    .Number
      circle(120px)
      flex-center-children()
      flex-shrink 0
      font-size 3rem
      background-color $color_green
      color #fff
      font-weight bold
    .Text
      margin 0 30px
      span
        text-align left
        padding-bottom 10px
    .Empty
      circle(120px)
    ul
      margin-left 20px
      margin-top 10px
    li
      padding-bottom 10px

  .SplitLine
    border-top 1px solid $color_grey_lighter
    width 500px
    margin-top 50px
    margin-bottom 50px

  .ButtonWrap
    margin-top 50px
</style>
