<template>
  <div class="Header">
    <!-- Logo -->
    <div class="LogoWrap">
      <img
        src="@/assets/svg/logos/milva-logo-white.svg" />
      <span><b>E</b>ncyclopidia</span>
    </div>

    <!-- Navigation -->
    <div class="Navigation">
      <div class="LeftSide">
        <!-- Welcome link -->
        <router-link
          :to="{ name: 'Welcome' }"
          class="NavItem"
          :class="{'Active': $route.name === 'Welcome'}">
          {{ mixWB('Velkommen') }}
        </router-link>

        <!-- Schema links -->
        <router-link
          v-for="item in questionSchemas"
          :key="item.id"
          :to="{ name: 'Questionnaire', params: { questionnaireName: item.name } }"
          class="NavItem"
          :class="{'Active': item.name === $route.params.questionnaireName}">
          {{ mixWB(item.wordbook) }}
        </router-link>

        <!-- Done link -->
        <router-link
          v-if="currentUserNextStep.allDone"
          :to="{ name: 'Done' }"
          class="NavItem"
          :class="{'Active': $route.name === 'Done'}">
          {{ mixWB('Færdig') }}
        </router-link>
      </div>
      <div class="RightSide">
        <span
          class="NavItem"
          @click="onLogOutClick">
          {{ mixWB('LOG_OUT') }}
        </span>
      </div>
    </div>

    <!-- Admin -->
    <router-link
      :to="{ name: 'AdminDashboard' }"
      class="AdminWrap"
      v-if="currentUserData.isAdmin">
      {{ mixWB('W: Admin') }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters([
      'questionSchemas',
      'currentUserNextStep',
      'currentUserData',
    ]),
  },
  methods: {
    onLogOutClick() {
      this.$router.push({ name: 'LogOut' })
    },
  },
}
</script>

<style lang="stylus" scoped>
  .Header
    background-color #000
    background-image url('/img/bg-1.jpg')
    background-position center top
    background-size cover
    margin-bottom 50px

  .LogoWrap
    display flex
    justify-content center
    align-items center
    padding 30px
    img
      display block
      width 187px
      height 50px
      margin-right 15px
    span
      color #fff
      font-size 2.25rem
      b
        font-size 4.25rem
        color #fff

  .Navigation
    height 50px
    background-color rgba(#fff, 0.75)
    display flex
    justify-content space-between
    padding 0 30px

  .LeftSide
    display flex
    align-items center

  .NavItem
    height 100%
    display flex
    align-items center
    padding 1px 20px 0px
    transition all 0.1s ease-out
    cursor pointer
    &:hover
      background-color $color_grey_light
    &.Active
      background-color $color_salmon
      color #fff

  .AdminWrap
    position absolute
    top 15px
    left 15px
    padding 5px 10px
    background-color rgba(#fff, 0.2)
    color #fff
    font-size 0.75rem
</style>
